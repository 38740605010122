<template>
    <div>
        <div class="pd30">
            <div class="flex alcenter">
                <a-button type="primary" icon="plus" @click="addPartnerAct()">添加权限组</a-button>
            </div>

            <div class="mt20">
                <div class="wxb-table-gray">
                    <a-table rowKey="group_id" :columns="columns" :pagination="pagination" @change="handleTableChange"
                             :data-source="datas"
                             :loading="loading">
                        <div class="flex alcenter center" slot="status" slot-scope="status">
                            <a-tag v-if="status == 1" color="green">
                                启用
                            </a-tag>
                            <a-tag v-if="status == 0" color="red">
                                禁用
                            </a-tag>
                        </div>
                        <template slot="action" slot-scope="record,index">
                            <div class="flex center">
                                <a-dropdown placement="bottomRight">
									<span class="more-act">
										<i class="iconfont iconmore_gray"></i>
									</span>
                                    <a-menu slot="overlay">
                                        <a-menu-item>
                                            <a class="menu-act" href="javascript:;" @click="editPartnerAct(record)">
                                                <i class="iconfont ft14 iconedit"></i>
                                                <span class="ml10">编辑</span>
                                            </a>
                                        </a-menu-item>
                                        <a-menu-item>
                                            <a class="menu-act" href="javascript:;" @click="del(record)">
                                                <i class="iconfont ft14 icondelete"></i>
                                                <span class="ml10">删除</span>
                                            </a>
                                        </a-menu-item>
                                    </a-menu>
                                </a-dropdown>
                            </div>
                        </template>
                    </a-table>
                </div>
            </div>

            <div v-if="editPartnerLoading">
                <edit-platform-group :group_id="group_id" :module="module" :visible="editPartnerLoading"
                                     @cancel="cancelEditPartner" @ok="okEditPartner"></edit-platform-group>
            </div>
        </div>
    </div>
</template>

<script>
import {listMixin} from '@/common/mixin/list.js';
import editPlatformGroup from './editPlatformGroup.vue';

export default {
    components: {
        editPlatformGroup,
    },
    mixins: [listMixin],
    data() {
        return {
            loading: false,
            editPartnerLoading: false,
            group_id: 0,
            module: 'shop',
            pagination: {
                current: 1,
                pageSize: 10, //每页中显示10条数据
                total: 0,
            },
            columns: [
                {title: 'ID', dataIndex: 'group_id', align: 'center'},
                {title: '名称', dataIndex: 'title', align: 'center'},
                {title: '绑定数', dataIndex: 'num', align: 'center', ellipsis: true},
                {title: '创建时间', dataIndex: 'create_time', align: 'center'},
                {title: '状态', dataIndex: 'status', align: 'center', scopedSlots: {customRender: 'status'}},
                {title: '操作', key: 'operation', align: 'center', scopedSlots: {customRender: 'action'}},
            ],
            datas: [],
        }
    },
    computed: {
        getAccountUrl() {
            return process.env.VUE_APP_ACCOUNT_URL
        }
    },
    created() {

    },
    methods: {
        getLists() {
            if (this.loading === true) return;
            this.loading = true;
            this.$http.api('admin/shopAuthGroupLists', {
                limit: this.pagination.pageSize,
                page: this.pagination.current,
                module: 'shop'
            }).then(res => {
                this.pagination.total = res.total;
                this.datas = res.list;
                this.loading = false;
            }).catch(res => {
                console.log(res);
                this.loading = false;
            })
        },
        handleTableChange(pagination, filters, sorter) {
            this.pagination.current = pagination.current;
            this.getLists();
        },
        addPartnerAct() {
            this.group_id = 0;
            this.editPartnerLoading = true;
        },
        editPartnerAct(record) {
            this.group_id = record.group_id;
            this.editPartnerLoading = true;
        },
        cancelEditPartner() {
            this.editPartnerLoading = false;
        },
        okEditPartner() {
            this.editPartnerLoading = false;
            this.getLists();
        },
        del(record) {
            this.$confirm({
                title: '确认删除这个权限组吗？',
                okText: "确定",
                okType: "danger",
                cancelText: "取消",
                onOk: () => {
                    return new Promise((resolve, reject) => {
                        this.$http.api('platform/delAuthGroup', {
                            group_id: record.group_id,
                            module:'shop'
                        }).then(res => {
                            this.$message.success('删除成功', 1, () => {
                                this.getLists();
                            });
                        }).catch(res => {
                            console.log(res);
                        }).finally(() => {
                            resolve();
                        })
                    })
                }
            })
        },
    }
}
</script>

<style>
</style>
