<template>
    <div>
        <a-modal :title="getTitle" :width="800" :visible="visible" @cancel="handleCancel">
            <template slot="footer">
                <a-button key="back" @click="handleCancel">
                    取消
                </a-button>
                <a-button key="submit" type="primary" @click="onSubmit" :loading="confirmLoading">
                    保存
                </a-button>
            </template>
            <a-spin :spinning="loading">
                <div class="bg-w pd40">
                    <div>

                        <a-form-model ref="ruleForm" :model="form" :label-col="labelCol" :wrapper-col="wrapperCol">
                            <a-form-model-item ref="title" label="分组名称" help="20字以内" required prop="title">
                                <a-input v-model="form.title"/>
                            </a-form-model-item>

                            <a-form-model-item ref="module" label="模块名"  prop="module">
                                <a-input v-model="form.module" :disabled="true"/>
                            </a-form-model-item>
                            <a-form-model-item label="是否显示" prop="status">
                                <a-switch v-model="form.status"/>
                            </a-form-model-item>

                            <a-form-model-item label="备注说明" prop="desc">
                                <a-input v-model="form.desc" type="textarea"/>
                            </a-form-model-item>
                            <a-tree
                                v-model="checkedKeys"
                                :checkable="true"
                                :expanded-keys="expandedKeys"
                                :auto-expand-parent="autoExpandParent"
                                :selected-keys="selectedKeys"
                                :tree-data="treeData"
                                @expand="onExpand"
                                @select="onSelect"
                            />
                        </a-form-model>
                    </div>
                </div>
            </a-spin>
        </a-modal>
    </div>
</template>

<script>
export default {
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        group_id: {
            type: Number,
            default: 0
        },
        module:{
            type:String,
            default:''
        }
    },
    //defaultCheckedKeys
    data() {
        return {
            expandedKeys: [],
            autoExpandParent: true,
            checkedKeys: [],
            selectedKeys: [],
            treeData:[],
            loading: false,
            confirmLoading: false,
            labelCol: {span: 4},
            wrapperCol: {span: 14},
            other: '',
            pidList: [],
            form: {
                group_id:0,
                title: '',
                module: 'shop',
                status: true,
                desc: '',
            },
        }
    },
    computed: {
        getTitle() {
            if (this.group_id === 0) {
                return "添加运营账号";
            } else {
                return "编辑运营账号"
            }
        }
    },
    watch: {
        checkedKeys(val) {
            console.log('onCheck', val);
        },
    },
    created() {
        this.form.group_id = this.group_id;
        this.form.module = this.module;
        this.loaddata();
    },
    methods: {
        loaddata() {
            if (this.loading === true) return;
            this.loading = true;
            this.$http.api('platform/getTree', {
                module:this.form.module,
                group_id: this.form.group_id,
            }).then(res => {
                this.treeData = res.list
                if(res.group instanceof Object) {
                    if (Object.keys(res.group).length !== 0) {
                        res.group.status = !!res.group.status;
                        this.form = res.group
                    }
                }
                if(res.checkedKeys instanceof Object) {
                    if (Object.keys(res.checkedKeys).length !== 0) {
                        this.checkedKeys = res.checkedKeys
                    }
                }
                this.loading = false;
            }).catch(res => {
                console.log(res);
                this.loading = false;
            })
        },

        /**
         * 取消弹窗
         */
        handleCancel() {
            this.$emit("cancel");
        },

        /**
         * 提交数据
         */
        onSubmit() {
            if (this.confirmLoading === true) return;
            this.confirmLoading = true;
            let p = this.form;
            p.status_s = this.form.status ? 1 : 0;
            p.tree = this.checkedKeys;
            this.$http.api('admin/shopGroupSave', p).then(res => {
                this.$message.success('操作成功', 1, () => {
                    this.confirmLoading = false;
                    this.$emit("ok");
                })
            }).catch(res => {
                console.log(res);
                this.confirmLoading = false;
            })

        },
        onExpand(expandedKeys) {
            console.log('onExpand', expandedKeys);
            // if not set autoExpandParent to false, if children expanded, parent can not collapse.
            // or, you can remove all expanded children keys.
            this.expandedKeys = expandedKeys;
            this.autoExpandParent = false;
        },
        onCheck(checkedKeys) {
            console.log('onCheck', checkedKeys);
            this.checkedKeys = checkedKeys;
        },
        onSelect(selectedKeys, info) {
            console.log('onSelect', info);
            this.selectedKeys = selectedKeys;
        },

    }
}
</script>

<style>

</style>
